import CreatorsActionTypes from "./creators.types"

const INITIAL_STATE = {
  creators: [],
  creator: null,
  loading: false,
  filterParameters: null,
  creatorTextSearch: null,
}

const creatorsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CreatorsActionTypes.SET_CREATORS:
      return {
        ...state,
        creators: action.payload,
      }
    case CreatorsActionTypes.SET_CREATOR:
      return {
        ...state,
        creator: action.payload,
      }
    case CreatorsActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case CreatorsActionTypes.SET_FILTER_PARAMETERS:
      return {
        ...state,
        filterParameters: action.payload,
      }
    case CreatorsActionTypes.SET_CREATOR_TEXT_SEARCH:
      return {
        ...state,
        creatorTextSearch: action.payload,
      }
    default:
      return state
  }
}

export default creatorsReducer
