import React, { useEffect, useState, useRef } from "react"
import Section from "../../layouts/core/Section"
import {
  Header,
  HeaderGroup,
  HeaderGroupCenter,
  HeaderGroupLeft,
  HeaderGroupRight,
} from "../../layouts/core/Header"
import Body from "../../layouts/core/Body"
import Title from "../../layouts/core/Title"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Button from "react-bootstrap/Button"
import SpinnerComponent from "../../components/commons/SpinnerComponent"
import { useSelector } from "react-redux"
import { selectAccounts } from "../../redux/account/account.selectors"
import { SubHeader, SubHeaderGroup } from "../../layouts/core/SubHeader"
import useUserFeedbackMessage from "../../hooks/useUserFeedbackMessage"
import {
  getHashtagId,
  getHashtagName,
  getHashtagRecentMedia,
  getHashtagTopMedia,
  getRecentlySearchedHashtags,
} from "../../services/InstagamService"
import ReelerButton from "../../components/commons/ReelerButton"
import * as S from "./InstagramSearch.styles"
import CreatableSelect from "react-select/creatable"
import { capitalize } from "lodash"

import InfiniteScroll from "react-infinite-scroll-component"
import axios from "axios"
import ReelerTooltip from "../../components/commons/ReelerTooltip/ReelerTooltip"
import MediaObject from "./components/MediaObject"
import MediaObjectModal from "./components/MediaObjectModal/MediaObjectModal"
import NoContent from "../../components/commons/NoContent"
import { FaExclamation, FaFilter, FaTimesCircle } from "react-icons/fa"
import Text from "../../components/commons/Text"
import { Link } from "react-router-dom"
import { MAIN_ROUTES } from "../../constants/routes"
import Icon from "../../components/commons/Icon"
import ReelerPopover from "../../components/commons/ReelerPopover"
import { MEDIA_TYPE } from "../../constants"
import { Col, Row } from "react-bootstrap"

const FETCH_LIMIT = 10

export default function InstagramSearchPage() {
  const [loading, setLoading] = useState(false)
  const [searchInput, setSearchInput] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [isFetchingMoreObjects, setIsFetchingMoreObjects] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [mediaObjects, setMediaObjects] = useState(null)
  const [nextPagingLink, setNextPagingLink] = useState(null)
  const [hashtagLimitSearch, setHashtagLimitSearch] = useState(null)
  const [previouslySearchedHashtags, setPreviouslySearchedHashtags] = useState(
    []
  )
  const [isClearable, setIsCleaable] = useState(false)
  const [filter, setFilter] = useState({})
  const [searchType, setSearchType] = useState("top")
  const [igBusinessAccountId, setIgBusinessAccountId] = useState(null)
  const [numberOfFetches, setNumberOfFetches] = useState(0)
  const account = useSelector(selectAccounts)
  const { setErrorMessage } = useUserFeedbackMessage()

  useEffect(() => {
    if (account) {
      setLoading(true)

      setIgBusinessAccountId(
        account?.integrations?.instagram?.accounts[0]
          ?.ig_business_account_profile?.id
      )

      setLoading(false)
      // Check how many ig search limit
    }
  }, [account])

  useEffect(() => {
    if (
      mediaObjects?.length < 20 &&
      nextPagingLink &&
      !isFetchingMoreObjects &&
      !isSearching &&
      numberOfFetches < FETCH_LIMIT
    ) {
      console.log("Fetch more: ", numberOfFetches)
      handleLoadMoreMediaObjects()
      setNumberOfFetches(prev => prev + 1)
    }
  }, [mediaObjects, nextPagingLink, isFetchingMoreObjects, isSearching])

  const handleSearchInput = hashtag => {
    if (hashtag) {
      setSearchInput(hashtag)
      handleSearch(hashtag, searchType)
    } else {
      handleClearSearch()
    }
  }

  const handleSearch = async (hashtag, type) => {
    if (!hashtag) return

    if (!igBusinessAccountId) return

    setIsSearching(true)
    setMediaObjects(null)
    setNextPagingLink(null)
    setHasMore(false)
    setIsFetchingMoreObjects(false)
    setNumberOfFetches(0)
    // Get hashtag id

    const searchTerm = hashtag.replaceAll("#", "")
    const { long_lived_user_token } = account?.integrations?.instagram

    try {
      const hashtagId = await getHashtagId(
        igBusinessAccountId,
        searchTerm,
        long_lived_user_token
      )

      let response

      if (type === "top") {
        response = await getHashtagTopMedia(
          hashtagId,
          igBusinessAccountId,
          long_lived_user_token
        )
      } else {
        response = await getHashtagRecentMedia(
          hashtagId,
          igBusinessAccountId,
          long_lived_user_token
        )
      }

      const filteredMediaObjects = applyFilters(response?.data)
      const flattenedMediaObjects = flattenArray(filteredMediaObjects)

      setMediaObjects(flattenedMediaObjects)

      if (!response?.paging?.next) {
        setNextPagingLink(null)
        setHasMore(false)
      } else {
        setNextPagingLink(response?.paging?.next)
        setHasMore(true)
      }

      await checkHashtagLimitSearch(long_lived_user_token)

      setIsSearching(false)
    } catch (err) {
      console.log(err)
      let errorMsg = ""
      if (err?.response?.data?.error?.error_subcode === 2207034) {
        errorMsg =
          "You can query a maximum of 30 unique hashtags on behalf of your Instagram Business Account within a rolling, 7 day period. Once you query a hashtag, it will count against this limit for 7 days. Subsequent queries on the same hashtag within this time frame will not count against your limit, and will not reset its initial query 7 day timer."
      } else if (err?.response?.data?.error?.error_subcode === 2207024) {
        errorMsg =
          "The hashtag has to already exist on Instagram. If you want to use a new hashtag, please begin by posting the hashtag anywhere on Instagram – then please try again to register it in Reeler."
      } else if (err?.response?.data?.error?.error_subcode === 463) {
        errorMsg =
          "The connection to Instagram has expired and you need to refresh the Instagram connection. <a class='notification-link' href='/settings/integrations'>Refresh connection here</a>"

        // TODO: Update the account and remove the exisiting connection
      } else if (err?.response?.data?.error?.error_user_title != undefined) {
        errorMsg = err.response.data.error.error_user_title
      } else {
        errorMsg = err?.response?.data?.error?.message
      }
      setErrorMessage(errorMsg, 5000)
      setIsSearching(false)
    }
  }

  const handleLoadMoreMediaObjects = async () => {
    if (nextPagingLink) {
      setIsFetchingMoreObjects(true)
      try {
        const { data } = await axios({
          url: nextPagingLink,
          method: "get",
        })

        console.log("Data", data)

        const filteredMediaObjects = applyFilters(data?.data)
        const flattenedMediaObjects = flattenArray(filteredMediaObjects)

        setMediaObjects(prev =>
          prev
            ? [...prev, ...flattenedMediaObjects]
            : [...flattenedMediaObjects]
        )
        if (data?.paging?.next && data?.data?.length > 0) {
          console.log("Has more")
          setNextPagingLink(data?.paging?.next)
          setHasMore(true)
        } else {
          console.log("No more")
          setNextPagingLink(null)
          setHasMore(false)
        }

        setIsFetchingMoreObjects(false)
      } catch (e) {
        console.log("Instagram Error", e)
      }
    } else {
      setHasMore(false)
      setIsFetchingMoreObjects(false)
    }
  }

  const applyFilters = mediaObjectsToBeFiltered => {
    if (!filter) return mediaObjectsToBeFiltered
    return mediaObjectsToBeFiltered.filter(item => {
      for (var key in filter) {
        if (key === "hashtags") {
          const creatorTags = item?.caption
            ?.toLowerCase()
            ?.match(/#[\w\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+/g)
            ?.map(s => s.slice(1))

          if (!creatorTags?.some(e => filter?.hashtags?.includes(e))) {
            return false
          }
        } else if (item[key] === undefined || item[key] !== filter[key]) {
          return false
        }
      }
      return true
    })
  }

  const flattenArray = array => {
    const flattened = [] // Skapa en ny array för resultatet

    // En rekursiv funktion för att lägga till objekt och dess barn
    const addObject = obj => {
      if (obj.media_type === "CAROUSEL_ALBUM") {
        const { children, ...itemWithoutChildren } = obj // Separera children från objektet
        // Om det finns children, lägg till dem rekursivt
        if (children && Array.isArray(children?.data)) {
          children?.data?.forEach(child => {
            let flattendChild = { ...itemWithoutChildren, ...child }
            flattened.push(flattendChild)
          })
        }
      } else {
        flattened.push(obj) // Lägg till objektet utan children i den nya arrayen
      }
    }

    // Gå igenom varje objekt i den ursprungliga arrayen
    array.forEach(obj => addObject(obj))

    return flattened
  }

  const addFilters = (type, value) => {
    if (
      (typeof value === "string" && value !== "showAll") ||
      (Array.isArray(value) && value.length > 0)
    ) {
      setFilter({ ...filter, [type]: value })
      return
    }

    const newFilter = { ...filter }

    delete newFilter[type]
    filter ? setFilter(newFilter) : setFilter({})
  }

  const handleSearchType = type => {
    setSearchType(type)
    if (!searchInput) return
    handleSearch(searchInput, type)
  }

  const handleClearSearch = () => {
    setSearchInput(null)
    setMediaObjects(null)
    setHasMore(false)
    setNextPagingLink(null)
    setIsFetchingMoreObjects(false)
    setFilter({})
  }

  useEffect(() => {
    if (account && igBusinessAccountId) {
      const { long_lived_user_token } = account?.integrations?.instagram

      if (long_lived_user_token) {
        checkHashtagLimitSearch(long_lived_user_token)
      }
    }
  }, [account, igBusinessAccountId])

  const checkHashtagLimitSearch = async accessToken => {
    try {
      const recentlySearchHashtags = await getRecentlySearchedHashtags(
        igBusinessAccountId,
        accessToken
      )

      const promises = recentlySearchHashtags?.data.map(async o => {
        const hashtag = await getHashtagName(o.id, accessToken)
        if (!hashtag) return
        return hashtag
      })

      const hashtagArray = await Promise.all(promises)

      hashtagArray.sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1
        }
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1
        }
        return 0
      })

      setPreviouslySearchedHashtags(hashtagArray)

      setHashtagLimitSearch(recentlySearchHashtags?.data?.length)
    } catch (error) {
      console.log(error)
    }
  }
  const formatOption = l => ({
    label: l,
    value: l,
  })

  return (
    <Section>
      <Header>
        <HeaderGroup>
          <HeaderGroupLeft>
            <Title>Instagram search</Title>
          </HeaderGroupLeft>
          <HeaderGroupCenter></HeaderGroupCenter>
          <HeaderGroupRight></HeaderGroupRight>
        </HeaderGroup>
      </Header>
      <SubHeader>
        <SubHeaderGroup>
          <div className="d-flex flex-row align-items-center">
            {account ? (
              <>
                <InputGroup className="me-3">
                  <CreatableSelect
                    id="hashtag-search"
                    styles={{
                      container: () => ({
                        // none of react-select's styles are passed to <Control />
                        width: "400px",
                        marginRight: "1rem",
                      }),
                    }}
                    isDisabled={
                      account?.integrations?.instagram === null ||
                      isSearching ||
                      !hashtagLimitSearch
                    }
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--reeler-o-25)",
                        primary: "var(--reeler-light)",
                      },
                    })}
                    placeholder="Enter the required hashtag."
                    createOptionPosition="first"
                    formatCreateLabel={hashtag => `Search for ${hashtag}`}
                    isClearable={searchInput}
                    value={searchInput ? formatOption(searchInput) : null}
                    onChange={hashtag => handleSearchInput(hashtag?.value)}
                    onCreateOption={hashtag => handleSearchInput(hashtag)}
                    options={previouslySearchedHashtags?.map(l =>
                      formatOption(l)
                    )}
                  />
                </InputGroup>
                <ReelerTooltip text="Find the top Instagram posts and reels, as determined by Meta.">
                  <Form.Check
                    inline
                    label="Top"
                    name="searchType"
                    type="radio"
                    id="top-media"
                    checked={searchType === "top"}
                    onChange={e => handleSearchType("top")}
                    disabled={isSearching}
                  />
                </ReelerTooltip>
                <ReelerTooltip text="Find recent Instagram posts and reels (from the last 24 hours).">
                  <Form.Check
                    inline
                    label="Recent"
                    name="searchType"
                    type="radio"
                    id="recent-media"
                    checked={searchType === "recent"}
                    onChange={e => handleSearchType("recent")}
                    disabled={isSearching}
                  />
                </ReelerTooltip>
                <div>
                  <ReelerPopover
                    btn={
                      <Icon
                        notification={
                          filter ? Object.keys(filter).length : null
                        }
                      >
                        <FaFilter />
                      </Icon>
                    }
                    title="Apply filters"
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ width: "400px" }}
                    >
                      <div className="d-flex flex-row align-items-center mb-3">
                        <Text size="sm" color="var(--reeler-icon)">
                          Media type:
                        </Text>
                        <Form.Check
                          inline
                          label="All"
                          name="mediaType"
                          className="ms-3"
                          type="radio"
                          id="media-type-all"
                          checked={!filter?.media_type}
                          onChange={e => addFilters("media_type", "showAll")}
                          disabled={isSearching}
                        />
                        <Form.Check
                          inline
                          label="Image"
                          name="mediaType"
                          type="radio"
                          id="media-type-image"
                          checked={filter?.media_type === MEDIA_TYPE.IMAGE}
                          onChange={e =>
                            addFilters("media_type", MEDIA_TYPE.IMAGE)
                          }
                          disabled={isSearching}
                        />
                        <Form.Check
                          inline
                          label="Video"
                          name="mediaType"
                          type="radio"
                          id="media-type-video"
                          checked={filter?.media_type === MEDIA_TYPE.VIDEO}
                          onChange={e =>
                            addFilters("media_type", MEDIA_TYPE.VIDEO)
                          }
                          disabled={isSearching}
                        />
                      </div>
                      <div className="d-flex flex-row align-items-center mb-3">
                        <ReelerTooltip text="Add additional hashtags to search for a combination of the required hashtag and any of the additional ones.">
                          <Text size="sm" color="var(--reeler-icon)">
                            Hashtags:
                          </Text>
                        </ReelerTooltip>
                        <InputGroup className="ms-3">
                          <CreatableSelect
                            id="additional-hashtags"
                            styles={{
                              container: () => ({
                                // none of react-select's styles are passed to <Control />
                                width: `100%`,
                              }),
                            }}
                            isMulti
                            isDisabled={isSearching}
                            theme={theme => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "var(--reeler-o-25)",
                                primary: "var(--reeler-light)",
                              },
                            })}
                            placeholder="Add a hashtag..."
                            createOptionPosition="first"
                            formatCreateLabel={hashtag =>
                              `Filter by ${hashtag}`
                            }
                            value={filter?.hashtags?.map(l => formatOption(l))}
                            onChange={newValue =>
                              addFilters(
                                "hashtags",
                                newValue.map(l => l.value)
                              )
                            }
                            onCreateOption={hashtag =>
                              addFilters(
                                "hashtags",
                                filter?.hashtags
                                  ? [
                                      ...filter?.hashtags,
                                      hashtag.replaceAll("#", "").toLowerCase(),
                                    ]
                                  : [hashtag.replaceAll("#", "").toLowerCase()]
                              )
                            }
                            options={previouslySearchedHashtags?.map(l =>
                              formatOption(l)
                            )}
                          />
                        </InputGroup>
                      </div>
                      <ReelerButton
                        variant="primary"
                        size="sm"
                        dispatch={() => handleSearch(searchInput, searchType)}
                        text="Apply filters"
                        disabled={isSearching || !searchInput}
                        loading={isSearching}
                      />
                    </div>
                  </ReelerPopover>
                </div>
              </>
            ) : null}
          </div>
          <div className="d-flex flex-row align-items-center">
            <ReelerTooltip
              placement="bottom"
              text="From each of your Instagram accounts, you can query up to 30 unique hashtags within a 7-day period, including those from your saved hashtag sources."
            >
              <Form.Control
                as="select"
                name="ig_business_account"
                value={igBusinessAccountId}
                onChange={e => setIgBusinessAccountId(e.target.value)}
                className="me-3"
                disabled={isSearching}
              >
                {account?.integrations?.instagram?.accounts.map(
                  (account, key) => (
                    <option
                      key={key}
                      value={account.ig_business_account_profile.id}
                    >
                      {account.ig_business_account_profile.username}
                    </option>
                  )
                )}
              </Form.Control>
            </ReelerTooltip>
            {hashtagLimitSearch ? (
              <div className="d-flex flex-row align-items-center">
                <FaExclamation size={14} className="icon-color" />
                <Text
                  size="md"
                  color="var(--reeler-icon)"
                >{`${hashtagLimitSearch}/30`}</Text>
              </div>
            ) : null}
          </div>
        </SubHeaderGroup>
      </SubHeader>
      <Body>
        <Row className="mx-0">
          <Col className="px-0">
            {account?.integrations?.instagram ? (
              <>
                <MediaObjectModal />
                {isSearching && !mediaObjects && <SpinnerComponent />}

                {mediaObjects && (
                  <InfiniteScroll
                    style={{ overflow: "none" }}
                    dataLength={mediaObjects.length}
                    next={() => handleLoadMoreMediaObjects()}
                    hasMore={hasMore}
                    loader={
                      <div className="d-flex justify-content-center py-3">
                        <SpinnerComponent size="sm" />
                      </div>
                    }
                    endMessage={
                      <div className="my-3 text-center">
                        <b>
                          You've reached the end — there's no more content to
                          display.
                        </b>
                      </div>
                    }
                  >
                    <S.MediaObjectsContainer>
                      {mediaObjects.map(mediaObject => {
                        return (
                          <MediaObject
                            key={mediaObject?.id}
                            mediaObject={mediaObject}
                          />
                        )
                      })}
                    </S.MediaObjectsContainer>
                  </InfiniteScroll>
                )}

                {false &&
                  mediaObjects &&
                  mediaObjects.length === 0 &&
                  !isSearching && <NoContent text="No content found" />}

                {!mediaObjects && !isSearching && (
                  <div className="d-flex justify-content-center">
                    <div className="w-75">
                      <NoContent text="Search for ‘Top’ or ‘Recent’ Instagram posts containing a specific hashtag. Use filters to narrow results, and add additional hashtags for a combined search. Results aren’t automatically imported to Reeler; scroll through to select which content assets to import and store." />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center pt-5">
                <img
                  className="mb-3"
                  height="150px"
                  src="../../women_working_out.svg"
                  alt=""
                />
                <p className="p-3">
                  You have not connected any Instagram Business Account.
                </p>

                <Button
                  as={Link}
                  className="btn-main"
                  to={MAIN_ROUTES.settingsIntegration.path}
                >
                  Connect your account here
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Body>
    </Section>
  )
}
