import React, { createRef } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"

import { FaEdit, FaEyeSlash, FaUserCircle } from "react-icons/fa"

import {
  ACCOUNT_PLANS,
  MAIN_ROUTES,
} from "../../../../../../../constants/routes"
import Icon from "../../../../../../../components/commons/Icon"

import ReelerTooltip from "../../../../../../../components/commons/ReelerTooltip/ReelerTooltip"
import AuthBasedComponent from "../../../../../../../components//authentication/AuthBasedComponent"
import EditCreator from "../../../../../components/EditCreator/EditCreator"
import Tag from "../../../../../../../components/commons/Tag"
import CountAssetsForCreator from "../../../CountAssetsForCreator/CountAssetsForCreator"
import { selectCurrentUser } from "../../../../../../../redux/user/user.selectors"

const CreatorRow = ({ creator }) => {
  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSelectCreator = () => {
    history.push(MAIN_ROUTES.creator.path + "/" + creator.id)
  }

  return (
    <tr>
      <td
        className="align-middle icon-btn"
        style={{ maxWidth: "300px" }}
        onClick={() => handleSelectCreator()}
      >
        <div className="d-flex flex-row w-100">
          <ReelerTooltip
            text={
              <div className="d-flex flex-column justify-content-start">
                <div>View creator:</div>
                {creator?.email && <small>{creator?.email}</small>}
                {creator?.phone && <small>{creator?.phone}</small>}
                {creator?.ig_username && <small>{creator?.ig_username}</small>}
              </div>
            }
          >
            <div className="d-flef flex-row text-truncate">
              {creator?.firstName || creator?.lastName ? (
                <>
                  {creator?.firstName ? creator?.firstName : ""}
                  {creator?.lastName && creator?.firstName ? " " : ""}
                  {creator?.lastName ? creator?.lastName : ""}
                </>
              ) : creator?.email ? (
                <div>{creator?.email}</div>
              ) : creator?.ig_username ? (
                <div>{creator?.ig_username}</div>
              ) : (
                <div>N/A</div>
              )}
            </div>
          </ReelerTooltip>
        </div>
      </td>

      <td className="align-middle">
        <div className="text-truncate">
          {typeof creator?.lists === "object" &&
            Object.keys(creator?.lists)
              ?.sort((a, b) => {
                if (a.toLowerCase() < b.toLowerCase()) {
                  return -1
                }
                if (a.toLowerCase() > b.toLowerCase()) {
                  return 1
                }
                return 0
              })
              .map((list, index) => (
                <Tag key={index} showRemoveIcon={false} disabled>
                  {list}
                </Tag>
              ))}
        </div>
      </td>
      <td className="align-middle text-center">
        {creator?.lastViewed && creator?.lastViewed[user.id] ? (
          <ReelerTooltip text="New assets since you last reviewed this creator">
            <CountAssetsForCreator
              creatorId={creator.id}
              lastViewed={creator?.lastViewed[user.id].toMillis()}
              variant="main"
            />
          </ReelerTooltip>
        ) : (
          <ReelerTooltip text="New assets since you last reviewed this creator">
            <CountAssetsForCreator creatorId={creator.id} variant="main" />
          </ReelerTooltip>
        )}
      </td>
      <td className="align-middle text-center">
        <ReelerTooltip text={"Number of assets"}>
          <CountAssetsForCreator creatorId={creator.id} variant="info" />
        </ReelerTooltip>
      </td>

      <td className="align-middle text-center">
        <div className="d-flex flex-row justify-content-end algign-items-center">
          <AuthBasedComponent
            plans={[
              ACCOUNT_PLANS.basic,
              ACCOUNT_PLANS.pro,
              ACCOUNT_PLANS.premium,
            ]}
          >
            <span
              className="d-flex justify-content-center align-items-center me-2"
              style={{ width: "30px" }}
            >
              <EditCreator
                title={"Edit creator"}
                btn={
                  <Icon tooltip="Edit creator">
                    <FaEdit />
                  </Icon>
                }
                creator={creator}
              />
            </span>
          </AuthBasedComponent>
        </div>
      </td>
    </tr>
  )
}

export default CreatorRow
