import React from "react"

import * as S from "./MediaObject.styles"
import { timeAgo } from "../../../../utils/CalculateTimeAgo"
import { FaComment, FaHeart, FaImages } from "react-icons/fa"
import Text from "../../../../components/commons/Text"
import ImportInstagramButton from "../ImportInstagramButton"
import { useDispatch } from "react-redux"
import { openMediaObjectModal } from "../../../../redux/instagram-search/instagramSearch.actions"
import { IoMdPlay } from "react-icons/io"
import { BsCollection, BsCollectionFill } from "react-icons/bs"
import ReelerTooltip from "../../../../components/commons/ReelerTooltip/ReelerTooltip"

export default function MediaObject({ mediaObject }) {
  const dispatch = useDispatch()
  const {
    media_type,
    media_url,
    caption,
    like_count,
    comments_count,
    timestamp,
  } = mediaObject

  const handleOpenMediaObjectModal = () => {
    dispatch(openMediaObjectModal(mediaObject))
  }

  return (
    <S.MediaObject>
      <S.Media onClick={handleOpenMediaObjectModal}>
        {media_type === "VIDEO" ? (
          <S.VideoContainer>
            <S.Video src={media_url} type="video/mp4" controls={false} />
            <S.PlayIcon>
              <IoMdPlay size="2.5rem" />
            </S.PlayIcon>
          </S.VideoContainer>
        ) : media_type === "IMAGE" ? (
          <S.Image src={media_url} alt="" />
        ) : (
          <>
            <S.TopRight>
              <ReelerTooltip text="Instagram album">
                <BsCollectionFill />
              </ReelerTooltip>
            </S.TopRight>
            <S.Image src={media_url} alt="" />
          </>
        )}
      </S.Media>
      <S.Body onClick={handleOpenMediaObjectModal}>
        <S.Caption>{caption}</S.Caption>
        <div className="d-flex flex-row justify-content-between align-items-center mb-2">
          <div>
            <Text size="sm" color="var(--reeler-icon)">
              {timeAgo(new Date(timestamp))}
            </Text>
          </div>
          <div className="d-flex flex-row align-items-center">
            <div className="me-1">
              <FaComment className="icon-color" />
            </div>
            <div>
              <Text size="sm" color="var(--reeler-icon)">
                {comments_count}
              </Text>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <div className="me-1">
              <FaHeart className="icon-color" />
            </div>
            <div>
              <Text size="sm" color="var(--reeler-icon)">
                {like_count}
              </Text>
            </div>
          </div>
        </div>
      </S.Body>
      <S.Footer>
        <ImportInstagramButton media={mediaObject} />
      </S.Footer>
    </S.MediaObject>
  )
}
