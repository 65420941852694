import UserActionTypes from "../user/user.types"
import CampaignActionTypes from "./campaign.types"

const INITIAL_STATE = {
  selectedCampaign: null,
  campaign: {},
  campaigns: [],
  dailyAssets: 0,

  showEditModal: false,
  selectedField: null,
  isLoading: false,
  errorMessage: undefined,
}

const campaignReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CampaignActionTypes.FETCH_CAMPAIGNS_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: undefined,
      }
    case CampaignActionTypes.FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload.campaigns,
        isLoading: false,
        errorMessage: undefined,
      }
    case CampaignActionTypes.FETCH_CAMPAIGNS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      }
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        campaigns: [],
        dailyAssets: 0,
      }
    case CampaignActionTypes.SET_SELECTED_CAMPAIGN:
      return {
        ...state,
        selectedCampaign: action.payload,
      }
    case CampaignActionTypes.CLEAR_SELECTED_CAMPAIGN:
      return {
        ...state,
        selectedCampaign: null,
      }
    case CampaignActionTypes.LOAD_CAMPAIGN:
    case CampaignActionTypes.SAVE_CAMPAIGN:
      return {
        ...state,
        campaign: action.campaign,
      }
    case CampaignActionTypes.CREATE_CAMPAIGN:
      return {
        ...state,
        campaign: action.payload,
        campaigns:
          state.campaigns.length > 0
            ? [...state.campaigns, action.payload]
            : [action.payload],
      }
    case CampaignActionTypes.UPDATE_CAMPAIGNS_WITH_CAMPAIGN:
      return {
        ...state,
        campaigns:
          state.campaigns.length > 0
            ? state.campaigns?.map(c =>
                c.id === action.payload.id ? action.payload : c
              )
            : [action.payload],
      }
    case CampaignActionTypes.DELETE_CAMPAIGN:
      return {
        ...state,
        campaign: null,
        selectedCampaign: null,
        campaigns: state.campaigns?.filter(c => c.id !== action.payload),
      }
    case CampaignActionTypes.CLEAR_CAMPAIGN:
      return {
        ...state,
        campaign: null,
      }
    case CampaignActionTypes.FORM_BUILDER_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: undefined,
      }
    case CampaignActionTypes.FETCH_FORM_SUCCESS:
      return {
        ...state,
        campaign: { ...state.campaign, formSchema: action.payload.formSchema },
        isLoading: false,
        errorMessage: undefined,
      }
    case CampaignActionTypes.ADD_FORM_FIELD:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          formSchema: {
            ...state.campaign.formSchema,
            order: action.order,
            properties: {
              ...state.campaign.formSchema.properties,
              [action.fieldId]: action.field,
            },
          },
        },
      }

    case CampaignActionTypes.REMOVE_FORM_FIELD:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          formSchema: {
            ...state.campaign.formSchema,
            order: action.order,
            properties: action.properties,
          },
        },
      }
    case CampaignActionTypes.CHANGE_FIELD_POSITION:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          formSchema: {
            ...state.campaign.formSchema,
            order: action.order,
          },
        },
      }
    case CampaignActionTypes.SELECT_FIELD:
      return {
        ...state,
        selectedField: action.fieldId,
      }
    case CampaignActionTypes.SHOW_EDIT_MODAL:
      return {
        ...state,
        showEditModal: action.showEditModal,
      }

    case CampaignActionTypes.CLEAR_SELECTED_FIELD:
      return {
        ...state,
        selectedField: null,
        showEditModal: false,
      }
    case CampaignActionTypes.UPDATE_FORM_DESIGN:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          formSchema: {
            ...state.campaign.formSchema,
            design: action.design,
          },
        },
      }

    case CampaignActionTypes.UPDATE_TAGS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          tags: action.tags,
        },
      }
    case CampaignActionTypes.RESET_TO_INITIAL_STATE:
      return {
        ...INITIAL_STATE,
      }

    default:
      return state
  }
}

export default campaignReducer
