import React, { useRef } from "react"
// 3rd-party libraries
import { Form } from "react-bootstrap"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectFormSchema } from "../../../../redux/campaign/campaign.selectors"
import { changeFieldPosition } from "../../../../redux/campaign/campaign.actions"

// Reeler
import FormField from "../components/FormField"
import AddNewFieldDropdown from "../components/AddNewFieldDropdown"
import CheckWidget from "./components/CheckWidget"
import FileWidget from "./components/FileWidget"
import InputWidget from "./components/InputWidget"
import TextWidget from "./components/TextWidget"
import MultiplechoiceWidget from "./components/MultipleChoiceWidget"
import ThirdPartyApprovalWidget from "./components/ThirdPartyApprovalWidget"
import ImageWidget from "./components/ImageWidget"
import SubmitButtonWidget from "./components/SubmitButtonWidget"
import DividerWidget from "./components/DividerWidget"
import TermsWidget from "./components/TermsWidget"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
// styled
import * as S from "../styles"
import * as SS from "./FormStyles"

export default function FormGenerator(props) {
  // Refs
  const refArray = useRef([])
  const formSchema = useSelector(selectFormSchema)

  return formSchema ? (
    <SS.Styles design={formSchema?.design}>
      {/* <S.AddFieldContainer>
        <AddNewFieldDropdown />
      </S.AddFieldContainer> */}

      <Droppable droppableId="formFields">
        {provided => (
          <S.Row
            className="OptionsRow"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {formSchema.order.map((fieldId, index) => {
              let field = formSchema.properties[fieldId]
              switch (field?.widget) {
                case "input":
                  return (
                    <FormField
                      index={index}
                      disableHover={false}
                      key={fieldId}
                      fieldId={fieldId}
                      field={field}
                    >
                      <InputWidget
                        fieldId={fieldId}
                        field={field}
                        index={index}
                        refArray={refArray}
                        design={formSchema?.design}
                      />
                    </FormField>
                  )
                case "text":
                  return (
                    <FormField
                      index={index}
                      disableHover={false}
                      key={fieldId}
                      fieldId={fieldId}
                      field={field}
                    >
                      <TextWidget fieldId={fieldId} field={field} />
                    </FormField>
                  )
                case "multiple_choice":
                  return (
                    <FormField
                      index={index}
                      disableHover={false}
                      key={fieldId}
                      fieldId={fieldId}
                      field={field}
                    >
                      <MultiplechoiceWidget
                        fieldId={fieldId}
                        field={field}
                        index={index}
                        refArray={refArray}
                        design={formSchema?.design}
                      />
                    </FormField>
                  )

                case "file":
                  return (
                    <FormField
                      index={index}
                      disableHover={false}
                      key={fieldId}
                      fieldId={fieldId}
                      field={field}
                    >
                      <FileWidget
                        fieldId={fieldId}
                        disabled={false}
                        field={field}
                        index={index}
                        refArray={refArray}
                        design={formSchema?.design}
                      />
                    </FormField>
                  )
                case "check":
                  return (
                    <FormField
                      index={index}
                      disableHover={false}
                      key={fieldId}
                      fieldId={fieldId}
                      field={field}
                    >
                      <CheckWidget
                        fieldId={fieldId}
                        field={field}
                        design={formSchema?.design}
                      />
                    </FormField>
                  )
                case "image":
                  return (
                    <FormField
                      index={index}
                      disableHover={false}
                      key={fieldId}
                      fieldId={fieldId}
                      field={field}
                    >
                      <ImageWidget fieldId={fieldId} field={field} />
                    </FormField>
                  )
                case "third_party_approval":
                  return (
                    <FormField
                      index={index}
                      disableHover={false}
                      key={fieldId}
                      fieldId={fieldId}
                      field={field}
                    >
                      <ThirdPartyApprovalWidget
                        fieldId={fieldId}
                        field={field}
                        design={formSchema?.design}
                      />
                    </FormField>
                  )
                case "submitButton":
                  return (
                    <FormField
                      index={index}
                      disableHover={false}
                      key={fieldId}
                      fieldId={fieldId}
                      field={field}
                    >
                      <SubmitButtonWidget
                        fieldId={fieldId}
                        field={field}
                        design={formSchema?.design}
                      />
                    </FormField>
                  )
                case "divider":
                  return (
                    <FormField
                      index={index}
                      disableHover={false}
                      key={fieldId}
                      fieldId={fieldId}
                      field={field}
                    >
                      <DividerWidget fieldId={fieldId} field={field} />
                    </FormField>
                  )
                case "terms":
                  return (
                    <FormField
                      index={index}
                      disableHover={false}
                      key={fieldId}
                      fieldId={fieldId}
                      field={field}
                    >
                      <TermsWidget
                        fieldId={fieldId}
                        field={field}
                        index={index}
                        refArray={refArray}
                        design={formSchema?.design}
                      />
                    </FormField>
                  )

                default:
                  return null
              }
            })}

            {provided.placeholder}
          </S.Row>
        )}
      </Droppable>
      {/* <S.AddFieldContainer>
        <AddNewFieldDropdown previousFieldId={formSchema.order.at(-1)} />
      </S.AddFieldContainer> */}
    </SS.Styles>
  ) : null
}
