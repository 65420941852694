import React, { useState } from "react"
import { database } from "../../firebase"
import firebase from "firebase/app"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  selectAccountId,
  selectAccounts,
} from "../../redux/account/account.selectors"
import { updateAccounts } from "../../redux/account/account.actions"

// 3rd-party
import { IoClose } from "react-icons/io5"
import CreatableSelect from "react-select/creatable"
import { Row, Col, Form, FormGroup } from "react-bootstrap"
// Styles
import ReelerButton from "../commons/ReelerButton"
import {} from "react-bootstrap"
import "../App.css"
import * as S from "../styles/styles"
import SpinnerComponent from "../commons/SpinnerComponent"
import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Title from "../../layouts/core/Title"
import Body from "../../layouts/core/Body"
import BackButton from "../commons/BackButton"
import Tag from "../commons/Tag"

const AccountTags = () => {
  const dispatch = useDispatch()
  const accountId = useSelector(selectAccountId)
  const accounts = useSelector(selectAccounts)
  const [filteredTags, setFilteredTags] = useState([])
  const [tagString, setTagString] = useState("")
  const [searchString, setSearchString] = useState("")
  const [loading, setLoading] = useState(false)

  const fetchAllTags = async () => {
    setLoading(true)

    // Get all assets for account

    if (accounts) {
      let campaignsRef = database.campaigns.where("accountId", "==", accountId)
      let allTags = []
      await campaignsRef.get().then(querySnapshot => {
        console.log("Number of campaigns: " + querySnapshot.size)
        querySnapshot.forEach(doc => {
          let campaign = doc.data()
          if (campaign.tags) {
            Object.keys(campaign.tags).forEach((tag, index) => {
              if (!allTags.includes(tag)) {
                allTags.push(tag)
              }
            })
          }
        })

        // update firebase and redux
        database.accounts
          .doc(accountId)
          .update({
            tags: firebase.firestore.FieldValue.arrayUnion(...allTags),
          })
          .then(() => {
            setLoading(false)
            // Update redux
            dispatch(
              updateAccounts({
                tags: accounts?.tags
                  ? [...accounts?.tags, ...allTags]
                  : allTags,
              })
            )
          })
          .catch(err => {
            setLoading(false)
          })
      })
    }
  }

  const handleNewTag = str => {
    setLoading(true)

    let newTags = []

    str
      .toLowerCase()
      .trim()
      .replace(/#+/g, "")
      .split(" ")
      .forEach(tag => {
        if (tag === "") {
          return
        }
        if (!accounts.tags.includes(tag)) {
          newTags.push(tag)
        }
      })

    database.accounts
      .doc(accountId)
      .update({
        tags: firebase.firestore.FieldValue.arrayUnion(...newTags),
      })
      .then(() => {
        setLoading(false)
        setTagString("")
        // Update redux
        dispatch(
          updateAccounts({
            tags: accounts?.tags ? [...accounts?.tags, ...newTags] : newTags,
          })
        )
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const deleteTag = tag => {
    setLoading(true)

    database.accounts
      .doc(accountId)
      .update({
        tags: firebase.firestore.FieldValue.arrayRemove(tag),
      })
      .then(() => {
        setLoading(false)
        // Update redux
        let tags = accounts.tags
        const index = tags.indexOf(tag)
        tags.splice(index, 1)
        dispatch(updateAccounts({ tags }))
      })
      .catch(err => {
        setLoading(false)
      })
  }

  return (
    <Section>
      <Header>
        <Title>Account Labels</Title>
        <div className="ms-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <strong>Manage account labels</strong>

        <Row>
          <Col md={10}>
            <p>
              Content labels are used to find content later, in your Reeler
              content library. To ensure consistency in tagging among team
              members, you can create a list of standard content labels known as
              “account labels". In essence, “account labels” help standardize
              the labels used by your team.
            </p>
            <p>
              Below, you can edit your list of “account labels”. These labels
              will appear as suggestions when you:
            </p>
            <ul>
              <li>Label content assets</li>
              <li>Search for content using the filter</li>
            </ul>
            <p>
              Please note that users can still manually type in different
              labels, even if they don’t appear in the list of account labels.
            </p>
          </Col>
        </Row>
        <FormGroup>
          {loading ? (
            <div>
              <SpinnerComponent />{" "}
              <span>
                {" "}
                This may take a while, do not leave or refresh the page.
              </span>
            </div>
          ) : null}
        </FormGroup>
        {/* <InputGroup className="mb-3 w-50">
            <FormControl
              type="text"
              size="sm"
              placeholder="Add a new tag..."
              className="tag-input"
              onChange={e => setTagString(e.target.value)}
              onKeyUp={e => (e.key === "Enter" ? handleNewTag() : null)}
            />
            <Button
              size="sm"
              onClick={() => handleNewTag()}
              disabled={
                loading || (tagString && tagString.length > 0) ? false : true
              }
              className="secondary-btn input-group-btn"
            >
              Add
            </Button>
          </InputGroup> */}
        <CreatableSelect
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "var(--reeler-o-25)",
              primary: "var(--reeler-light)",
            },
          })}
          placeholder="Search and add tags..."
          isClearable
          options={accounts?.tags
            ?.sort((a, b) => {
              if (a < b) {
                return -1
              }
              if (a > b) {
                return 1
              }
              return 0
            })
            ?.map(t => ({ value: t, label: t }))}
          onChange={(selectedOption, action) => {
            if (action.action === "clear") {
              setSearchString("")
            } else if (action.action === "create-option") {
              handleNewTag(action.option.value?.trim().toLowerCase())
            } else {
              setSearchString(selectedOption?.value)
            }
          }}
        />
        <Form.Text muted>
          Labels are for finding content later, in your Reeler content library.
          Here you can manage your account labels that can be used to label
          assets or content sources. Search or create a new label.
        </Form.Text>

        <div className="d-flex flex-wrap mt-3">
          {accounts &&
            accounts?.tags
              ?.filter(tag => tag.includes(searchString))
              ?.sort((a, b) => {
                if (a < b) {
                  return -1
                }
                if (a > b) {
                  return 1
                }
                return 0
              })
              ?.map((tag, index) => (
                <Tag key={index} onClick={() => deleteTag(tag)}>
                  {tag}
                </Tag>
              ))}
        </div>
      </Body>
    </Section>
  )
}

export default AccountTags
