import React, { useState } from "react"
import { Button, FormControl, InputGroup } from "react-bootstrap"
import {
  FaGripVertical,
  FaTrashAlt,
  FaRegCircle,
  FaRegSquare,
  FaTags,
} from "react-icons/fa"
import { IoClose } from "react-icons/io5"

// styled
import * as S from "../styles"
import { Icon } from "../../../styles/styles"
import { Draggable } from "react-beautiful-dnd"
import PopupModal from "./PopupModal"

export default function MultipleChoiceOption({
  option,
  index,
  settings,
  setSettings,
  id,
}) {
  const [activeOptionsRow, setActiveOptionsRow] = useState()
  const [newTags, setNewTags] = useState("")
  const [showTags, setShowTags] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [showWarningMessage, setShowWarningMessage] = useState({ message: "" })
  const handleFocus = event => event.target.select()

  const addTag = e => {
    let tags = {}
    if (newTags.trim() === "" || !newTags) {
      return
    } else {
      newTags.split(" ").forEach(tag => {
        // Prevent empty tags
        if (tag.trim() === "") {
          return
        }
        tags[tag.toLowerCase()] = true
      })
    }

    let options = settings.options
    let option = options[index]
    let updatedOption = {
      ...option,
      tags: { ...option.tags, ...tags },
    }
    options[index] = updatedOption

    setSettings({
      ...settings,
      options,
    })
    setNewTags("")
  }

  const removeOptionHandler = () => {
    setShowModal(true)
    setShowWarningMessage({ message: "Do you want to delete this option?" })
  }

  const deleteOption = () => {
    setSettings({
      ...settings,
      options: settings.options.filter((element, i) => {
        return i !== index
      }),
    })
    setShowModal(false)
  }

  const removeTag = tagToRemove => {
    let options = settings.options
    let option = options[index]

    const { [tagToRemove]: value, ...updatedTags } = option.tags

    let updatedOption = {
      ...option,
      tags: updatedTags,
    }
    options[index] = updatedOption

    setSettings({
      ...settings,
      options,
    })
  }

  const updateOption = (index, label) => {
    const updatedOptions = settings.options.map((obj, i) => {
      // 👇️ if i equals index, update object
      if (i === index) {
        return { ...obj, label: label }
      }
      // 👇️ otherwise return object as is
      return obj
    })
    setSettings({
      ...settings,
      options: updatedOptions,
    })
  }
  return (
    <>
      {showModal && (
        <S.ModalBodyContainer>
          <PopupModal
            message={showWarningMessage.message}
            onConfirm={deleteOption}
            closeModal={setShowModal}
          ></PopupModal>
        </S.ModalBodyContainer>
      )}

      <Draggable key={id.toString()} draggableId={id.toString()} index={index}>
        {provided => (
          <S.OptionsRow
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            $direction={"column"}
            key={index}
            onMouseEnter={() => setActiveOptionsRow(index)}
            onMouseLeave={() => setActiveOptionsRow(null)}
            $selected={showTags}
          >
            <S.LabelRow>
              <S.RowIconItem>
                {activeOptionsRow === index ? <FaGripVertical /> : null}
              </S.RowIconItem>
              {/* shows  dropdown format in add options icon checkbox,radio btns */}
              <S.RowIconItem>
                {settings?.format === "radio" ? (
                  <FaRegCircle size={13} />
                ) : settings?.format === "checkbox" ? (
                  <FaRegSquare size={13} />
                ) : (
                  index + 1
                )}
              </S.RowIconItem>
              <S.RowInputItem>
                {/* Label input option field */}
                <S.InputOption
                  id={"option" + index}
                  type="text"
                  value={option?.label}
                  onChange={e => updateOption(index, e.target.value)}
                  onFocus={handleFocus}
                />
              </S.RowInputItem>

              <S.RowIconItem onClick={() => setShowTags(!showTags)}>
                <S.Icon $size="20px">
                  {settings?.options[index]?.tags &&
                  Object.keys(settings?.options[index]?.tags).length > 0 ? (
                    <S.Notification>
                      {Object.keys(settings?.options[index]?.tags).length < 100
                        ? Object.keys(settings?.options[index]?.tags).length
                        : "..."}
                    </S.Notification>
                  ) : null}

                  <FaTags size={13} />
                </S.Icon>
              </S.RowIconItem>

              {/* deleting option */}
              <S.RowIconItem onClick={() => removeOptionHandler()}>
                <Icon $size="20px">
                  <FaTrashAlt size={13} />
                </Icon>
              </S.RowIconItem>
            </S.LabelRow>

            {showTags && (
              <S.TagsRow className="TagsRow flex-sm-row">
                {/* Tag pills */}
                <div>
                  {option?.tags &&
                    Object.keys(option?.tags).map((tag, i) => (
                      <span
                        key={i}
                        className="badge badge-pill py-1 px-2 m-2 tag"
                        onClick={() => removeTag(tag)}
                      >
                        {tag}
                        <IoClose size="14px" />
                      </span>
                    ))}
                </div>
                {/* Tag input*/}

                <div
                  style={{
                    width: ({ $selected }) => ($selected ? "19%" : "20%"),
                  }}
                >
                  <InputGroup>
                    <FormControl
                      style={{ fontSize: "12px", height: "10%" }}
                      onChange={e => setNewTags(e.target.value)}
                      onKeyUp={e => (e.key === "Enter" ? addTag() : null)}
                      value={newTags}
                      type="text"
                      size="sm"
                      placeholder="Add a new tag..."
                    />
                    <Button
                      size="sm"
                      style={{
                        fontSize: "12px",

                        padding: "0 3px",
                      }}
                      onClick={() => addTag()}
                      className="secondary-btn input-group-btn"
                    >
                      Add
                    </Button>
                  </InputGroup>
                </div>
              </S.TagsRow>
            )}
          </S.OptionsRow>
        )}
      </Draggable>
    </>
  )
}
