const CreatorsActionTypes = {
  SET_CREATORS: "SET_CREATORS",
  SET_CREATOR: "SET_CREATOR",
  SET_LOADING: "SET_LOADING",
  SET_FILTER_PARAMETERS: "SET_FILTER_PARAMETERS",
  SET_CREATOR_TEXT_SEARCH: "SET_CREATOR_TEXT_SEARCH",
  CLEAR_CREATOR: "CLEAR_CREATOR",
}

export default CreatorsActionTypes
