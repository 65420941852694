import React from "react"

import { useSelector } from "react-redux"

import { selectCreator } from "../../../../../redux/creators/creators.selectors"
import {
  FaBirthdayCake,
  FaEdit,
  FaEnvelope,
  FaHome,
  FaInstagram,
  FaMinus,
  FaPen,
  FaPenAlt,
  FaPhoneAlt,
  FaRegEdit,
  FaUserCircle,
} from "react-icons/fa"

import Tag from "../../../../../components/commons/Tag"
import { Form, Row, Col } from "react-bootstrap"
import { IoFemale, IoMale } from "react-icons/io5"
import { calculateAge } from "../../../../../utils/HelpFunctions"
import Text from "../../../../../components/commons/Text"
import { capitalize } from "lodash"
import ReelerTooltip from "../../../../../components/commons/ReelerTooltip/ReelerTooltip"
import EditCreator from "../../../components/EditCreator/EditCreator"
import Icon from "../../../../../components/commons/Icon"
import CreatorMoreActions from "../CreatorMoreActions"

export default function CreatorInfo() {
  const creator = useSelector(selectCreator)

  const { address } = creator

  const InfoLine = ({ label, icon, value }) => {
    return (
      <div className="d-flex flex-row align-items-center mb-1">
        {icon && <div className="me-2">{icon}</div>}
        {label && (
          <div className="me-2">
            <Text size="sm" weight="bold">
              {label}
            </Text>
          </div>
        )}
        <div>
          <Text size="sm">{value}</Text>
        </div>
      </div>
    )
  }

  return creator ? (
    <div className="mb-3">
      <div className="d-flex flex-row justify-content-between align-items-center pb-3">
        <div className="d-flex flex-row align-items-center">
          <Text size="lg" weight="bold" color="var(--reeler-title)">
            {creator?.firstName || creator?.lastName
              ? `${creator?.firstName} ${creator?.lastName}`
              : "Creator"}
          </Text>
          <div className="d-flex flex-row align-items-center ms-3">
            {creator?.birthday && (
              <div className="d-flex align-items-center mx-1">
                {calculateAge(creator?.birthday)}
              </div>
            )}
            <div className="d-flex align-items-center mx-1">
              {creator?.gender === "female" ? (
                <ReelerTooltip text="She/her">
                  <IoFemale className="d-flex align-items-center icon-color" />
                </ReelerTooltip>
              ) : creator?.gender === "male" ? (
                <ReelerTooltip text="He/him">
                  <IoMale className="d-flex align-items-center icon-color" />
                </ReelerTooltip>
              ) : creator?.gender === "other" ? (
                <ReelerTooltip text="Other gender">
                  <FaMinus className="d-flex align-items-center icon-color" />
                </ReelerTooltip>
              ) : null}
            </div>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center">
          {creator && (
            <EditCreator
              title="Edit creator"
              creator={creator}
              btn={
                <Icon tooltip="Edit creator">
                  <FaEdit />
                </Icon>
              }
            />
          )}

          <div className="ms-2">
            <CreatorMoreActions />
          </div>
        </div>
      </div>
      <Row>
        <Col>
          <Text size="md" weight="bold" color="var(--reeler-title)">
            Info
          </Text>
          <InfoLine
            icon={<FaEnvelope className="icon-color" />}
            value={creator?.email}
          />
          <InfoLine
            icon={<FaInstagram className="icon-color" />}
            value={creator?.ig_username}
          />
          <InfoLine
            icon={<FaPhoneAlt className="icon-color" />}
            value={creator?.phone}
          />
          <InfoLine
            icon={<FaBirthdayCake className="icon-color" />}
            value={creator?.birthday}
          />
          <InfoLine label={"Gender:"} value={capitalize(creator?.gender)} />
        </Col>
        <Col>
          <div className="d-flex flex-column mb-1">
            <Text size="md" weight="bold" color="var(--reeler-title)">
              Address
            </Text>
            {address?.addressLine1 && (
              <Text size="sm">{address?.addressLine1}</Text>
            )}
            {address?.addressLine2 && (
              <Text size="sm">{address?.addressLine2}</Text>
            )}
            {address?.postalCode && (
              <Text size="sm">{address?.postalCode}</Text>
            )}
            {address?.city && <Text size="sm">{address?.city}</Text>}
            {address?.state && <Text size="sm">{address?.state}</Text>}

            {address?.country && <Text size="sm">{address?.country}</Text>}
          </div>
        </Col>
        <Col>
          <Form.Group>
            <Text size="md" weight="bold" color="var(--reeler-title)">
              Notes
            </Text>
            <div
              style={{
                border: "1px solid var(--reeler-grey)",
                padding: "0.25rem",
                margin: "0.25rem 0",
              }}
            >
              {creator?.notes?.split("\n").map((line, index) => {
                return (
                  <p key={index}>
                    <Text size="sm">{line}</Text>
                  </p>
                )
              }) || (
                <p>
                  <Text size="sm">No notes</Text>
                </p>
              )}
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Text size="md" weight="bold" color="var(--reeler-title)">
            Lists
          </Text>
          <div className="d-flex flex-row align-items-center ">
            {typeof creator?.lists === "object" &&
              Object.keys(creator?.lists)
                ?.sort((a, b) => {
                  if (a.toLowerCase() < b.toLowerCase()) {
                    return -1
                  }
                  if (a.toLowerCase() > b.toLowerCase()) {
                    return 1
                  }
                  return 0
                })
                .map((list, index) => (
                  <Tag key={index} showRemoveIcon={false}>
                    {list}
                  </Tag>
                ))}
          </div>
        </Col>
      </Row>
    </div>
  ) : null
}
