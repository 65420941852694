import styled from "styled-components"

export const CloseButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: none;
  color: var(--reeler-icon);
  cursor: pointer;
  &:hover {
    color: var(--reeler-grey-darker);
  }
`
