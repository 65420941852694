import CampaignActionTypes from "./campaign.types"

export const fetchCampaignsStart = () => ({
  type: CampaignActionTypes.FETCH_CAMPAIGNS_START,
})

export const fetchCampaignsSuccess = payload => ({
  type: CampaignActionTypes.FETCH_CAMPAIGNS_SUCCESS,
  payload: payload,
})

export const fetchCampaignsFailure = error => ({
  type: CampaignActionTypes.FETCH_CAMPAIGNS_FAILURE,
  payload: error,
})

export const setSelectedCampaign = payload => ({
  type: CampaignActionTypes.SET_SELECTED_CAMPAIGN,
  payload,
})

export const clearSelectedCampaign = () => ({
  type: CampaignActionTypes.CLEAR_SELECTED_CAMPAIGN,
})

export const deleteCampaign = payload => ({
  type: CampaignActionTypes.DELETE_CAMPAIGN,
  payload: payload,
})
export const createCampaign = payload => ({
  type: CampaignActionTypes.CREATE_CAMPAIGN,
  payload: payload,
})

export const loadCampaign = campaign => ({
  type: CampaignActionTypes.LOAD_CAMPAIGN,
  campaign: campaign,
})

export const saveCampaign = campaign => ({
  type: CampaignActionTypes.SAVE_CAMPAIGN,
  campaign: campaign,
})

export const clearCampaign = () => ({
  type: CampaignActionTypes.CLEAR_CAMPAIGN,
})

export const formBuilderStart = () => ({
  type: CampaignActionTypes.FORM_BUILDER_START,
})

export const fetchFormSuccess = payload => ({
  type: CampaignActionTypes.FETCH_FORM_SUCCESS,
  payload: payload,
})

export const fetchFormFailure = error => ({
  type: CampaignActionTypes.FETCH_FORM_FAILURE,
  payload: error,
})

export const addFormField = (fieldId, field, order) => ({
  type: CampaignActionTypes.ADD_FORM_FIELD,
  fieldId: fieldId,
  field: field,
  order: order,
})
export const addNewFormField = (field, previousFieldId) => ({
  type: CampaignActionTypes.ADD_NEW_FORM_FIELD,
  field: field,
  previousFieldId: previousFieldId,
})
export const saveFormField = (fieldId, field) => ({
  type: CampaignActionTypes.SAVE_FORM_FIELD,
  fieldId: fieldId,
  field: field,
})

export const removeFieldFromForm = (order, properties) => ({
  type: CampaignActionTypes.REMOVE_FORM_FIELD,
  order: order,
  properties: properties,
})

export const changeFieldPosition = order => ({
  type: CampaignActionTypes.CHANGE_FIELD_POSITION,
  order: order,
})

export const selectField = fieldId => ({
  type: CampaignActionTypes.SELECT_FIELD,
  fieldId: fieldId,
})

export const clearSelectedField = () => ({
  type: CampaignActionTypes.CLEAR_SELECTED_FIELD,
})

export const setShowEditModal = show => ({
  type: CampaignActionTypes.SHOW_EDIT_MODAL,
  showEditModal: show,
})

export const updateFormDesign = design => ({
  type: CampaignActionTypes.UPDATE_FORM_DESIGN,
  design: design,
})

export const updateTags = tags => ({
  type: CampaignActionTypes.UPDATE_TAGS,
  tags,
})

export const updateCampaignsWithCampaign = payload => ({
  type: CampaignActionTypes.UPDATE_CAMPAIGNS_WITH_CAMPAIGN,
  payload: payload,
})
export const resetToInitialCampaignState = () => ({
  type: CampaignActionTypes.RESET_TO_INITIAL_STATE,
})
