import React, { useState, useCallback } from "react"
import { Form } from "react-bootstrap"
import { debounce } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { selectAccounts } from "../../../../../redux/account/account.selectors"
import SpinnerComponent from "../../../../../components/commons/SpinnerComponent"
import { setCreatorTextSearch } from "../../../../../redux/creators/creators.actions"

export default function CreatorsTextSearch() {
  const [searchString, setSearchString] = useState("")
  const account = useSelector(selectAccounts)
  const dispatch = useDispatch()

  function handleDebounceFn(inputValue) {
    dispatch(setCreatorTextSearch(inputValue))
  }

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleSearch(event) {
    setSearchString(event.target.value)
    debounceFn(event.target.value)
  }

  if (!account) return <SpinnerComponent />

  return (
    <Form.Control
      size="sm"
      placeholder="Search name, email, phone or Instagram username"
      value={searchString}
      onChange={handleSearch}
    />
  )
}
