import React from "react"

// 3rd-party
import { Row, Col } from "react-bootstrap"

// Reeler components

import * as S from "./styles"

import AddFormFields from "./components/AddFormFields"
import PreviewForm from "./components/PreviewForm"

export default function () {
  return (
    <S.FormBuilderContainer>
      <Row>
        <Col
          md={4}
          style={{ borderRight: "1px solid var(--reeler-grey-dark)" }}
        >
          <div className="m-2">
            <strong>Add form fields</strong>
          </div>
          <AddFormFields />
        </Col>
        <Col md={8} style={{ overflowY: "scroll", height: "100vh" }}>
          <PreviewForm />
        </Col>
      </Row>
    </S.FormBuilderContainer>
  )
}
