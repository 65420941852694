import styled from "styled-components"

export const Tag = styled.button`
  display: inline-block;
  background: ${props =>
    props?.highlighted ? "var(--reeler-super-light)" : "var(--reeler-grey)"};
  border: none;
  /* border-radius: 10rem; */
  margin: 0.25rem;
  padding: 0.25em 0.4em;
  text-transform: capitalize;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    background: var(--reeler-grey-darker);
    cursor: pointer;
  }
  &:disabled {
    cursor: default;
    color: var(--reeler-black);
  }
  &:focus {
    outline: 1px auto var(--reeler-light);
    box-shadow: 0 0 0 0.2rem var(--reeler-o-25) !important;
  }
`
