import React from "react"

import { Draggable, Droppable } from "react-beautiful-dnd"
import { FORM_FIELDS } from "../Fields"
import { useSelector } from "react-redux"

import * as S from "../styles"
import { FaGripVertical } from "react-icons/fa"
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
import ReelerAccordation from "../../../commons/ReelerAccordation"
import { selectFormSchema } from "../../../../redux/campaign/campaign.selectors"

const FormFieldGroup = ({ fields, groupType, label, visible }) => {
  const formSchema = useSelector(selectFormSchema)
  return formSchema ? (
    <ReelerAccordation
      title={<S.FormFieldTitle>{label}</S.FormFieldTitle>}
      visible={visible}
    >
      <S.FormFieldGroupContainer>
        {Object.keys(fields).map((fieldKey, index) => {
          const field = fields[fieldKey]
          return (
            field?.type === groupType && (
              <Draggable
                key={fieldKey}
                draggableId={fieldKey}
                index={index}
                isDragDisabled={
                  fieldKey === "file" && "file" in formSchema.properties
                }
              >
                {(provided, snapshot) => (
                  <>
                    <ReelerTooltip
                      text={
                        fieldKey === "file" && "file" in formSchema.properties
                          ? "There can only be one upload button in the form"
                          : "Drag and drop field in form"
                      }
                    >
                      <div>
                        <S.FormFieldItem
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          selected={
                            fieldKey === "file" &&
                            "file" in formSchema.properties
                          }
                        >
                          <FaGripVertical
                            style={{ color: "var(--reeler-grey)" }}
                          />
                          <div className="icon-color mx-3">{field.icon}</div>

                          <S.FormFieldItemText>
                            {field.label}
                          </S.FormFieldItemText>
                        </S.FormFieldItem>
                        {snapshot.isDragging && (
                          <S.FormFieldItem selected={snapshot.isDragging}>
                            <FaGripVertical
                              style={{ color: "var(--reeler-grey)" }}
                            />
                            <div className="icon-color mx-3">{field.icon}</div>

                            <S.FormFieldItemText>
                              {field.label}
                            </S.FormFieldItemText>
                          </S.FormFieldItem>
                        )}
                      </div>
                    </ReelerTooltip>
                  </>
                )}
              </Draggable>
            )
          )
        })}
      </S.FormFieldGroupContainer>
    </ReelerAccordation>
  ) : null
}

export default function AddFormFields() {
  return (
    <Droppable droppableId="GroupFormFields">
      {provided => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <FormFieldGroup
            label="Creator contact information"
            fields={FORM_FIELDS}
            visible={false}
            groupType="creator"
          />
          <FormFieldGroup
            label="Content information"
            fields={FORM_FIELDS}
            visible={false}
            groupType="content"
          />
          <FormFieldGroup
            label="Social media fields"
            fields={FORM_FIELDS}
            visible={false}
            groupType="some"
          />
          <FormFieldGroup
            label="Additional input fields"
            fields={FORM_FIELDS}
            visible={false}
            groupType="input"
          />
          <FormFieldGroup
            label="Text & Layout"
            fields={FORM_FIELDS}
            visible={false}
            groupType="layout"
          />
          <FormFieldGroup
            label="Legal"
            fields={FORM_FIELDS}
            visible={false}
            groupType="legal"
          />
        </div>
      )}
    </Droppable>
  )
}
