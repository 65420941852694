import React, { useState, useRef } from "react"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  loadCampaign,
  clearSelectedField,
  updateCampaignsWithCampaign,
  createCampaign,
} from "../../../redux/campaign/campaign.actions"

import { Modal, Form, Container, Row, Col } from "react-bootstrap"
import { database } from "../../../firebase"
import { useHistory } from "react-router-dom"
import { FaHashtag, FaRegEnvelope, FaWhatsapp } from "react-icons/fa"
import { MdAlternateEmail } from "react-icons/md"
import { FiUpload } from "react-icons/fi"
import { selectCurrentUser } from "../../../redux/user/user.selectors"
import {
  selectAccountId,
  selectAccounts,
} from "../../../redux/account/account.selectors"
import ReelerButton from "../../commons/ReelerButton"
import { CAMPAIGN_TYPE } from "../../../constants"
import { MAIN_ROUTES } from "../../../constants/routes"
import { FORM_DEFAULT_SETTINGS } from "../../../constants/formDefaultSettings"
import { selectCampaigns } from "../../../redux/campaign/campaign.selectors"

const CreateCampaign = ({ btn }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const currentUser = useSelector(selectCurrentUser)
  const accountId = useSelector(selectAccountId)
  const campaigns = useSelector(selectCampaigns)
  const [campaignType, setCampaignType] = useState(null)
  const [name, setName] = useState("")
  const history = useHistory()
  const modalRef = useRef()

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (name.trim().length < 3) {
      // Change console logs to error messages
      return
    }

    if (currentUser && accountId) {
      let newCampaign = {
        campaignName: name,
        campaignType: campaignType,
        embed: true,
        status: "active",
        accountId,
        userId: currentUser.id,
        createdAt: database.getCurrentTimestamp(),
      }

      if (campaignType === CAMPAIGN_TYPE.upload.type) {
        newCampaign.submitMessage =
          "Thank you for participating in our content source"
        newCampaign.buttonText = "Submit"
        newCampaign.formSchema = {
          order: ["file", "email", "terms", "submitButton"],
          properties: {
            file: {
              widget: "file",
              type: "string",
              format: "data-url",
              required: true,
              btnText: "Choose File",
              maxNoFilesPerSubmission: 10,
              btnColor: "#6c757d",
              btnFontColor: "#ffffff",
            },
            email: {
              widget: "input",
              type: "string",
              format: "email",
              required: true,
              placeholder: "Email",
              dataFieldName: "Email",
              export: true,
            },
            terms: {
              widget: "terms",
              format: "checkbox",
              export: true,
              terms:
                "I agree to the Terms & Conditions of this content source as well as the privacy policy of this website.",
              required: true,
            },
            submitButton: {
              widget: "submitButton",
              type: "submit",
              format: "button",
              required: true,
              btnText: "Submit",
              btnColor: "#007bff",
              btnFontColor: "#ffffff",
            },
          },
          design: {
            ...FORM_DEFAULT_SETTINGS,
          },
        }
      } else if (campaignType === CAMPAIGN_TYPE.whatsapp.type) {
        newCampaign.confirmationMessage = "Thank you for sending us content"
        newCampaign.contentRequirementMessage =
          "This WhatsApp number only accepts messages with a photo or video. Text-only messages are not accepted."
      }

      database.campaigns
        .add(newCampaign)
        .then(async doc => {
          const campaignSnapshot = await doc.get()
          const campaign = database.formatDoc(campaignSnapshot)
          dispatch(clearSelectedField())
          dispatch(createCampaign(campaign))
          switch (campaignType) {
            case CAMPAIGN_TYPE.upload.type:
              history.push(MAIN_ROUTES.campaignsFormBuilder.path)
              break
            case CAMPAIGN_TYPE.igHashtag.type:
              history.push(MAIN_ROUTES.campaignsEditHashtag.path)
              break
            case CAMPAIGN_TYPE.igMentions.type:
              history.push(MAIN_ROUTES.campaignsEditMention.path)
              break
            case CAMPAIGN_TYPE.email.type:
              history.push(MAIN_ROUTES.campaignsEditEmail.path)
              break
            case CAMPAIGN_TYPE.whatsapp.type:
              history.push(MAIN_ROUTES.campaignsEditWhatsApp.path)
              break
          }
        })
        .catch(err => {})
    }
  }

  const ChooseCampaignType = () => {
    return (
      <Container className="mt-4 mb-3">
        <Row>
          <Col>
            <Form.Label>1. Choose content source type:</Form.Label>
          </Col>
        </Row>
        <Container
          className="pb-2"
          style={{ background: "var(--reeler-grey)", borderRadius: "3px" }}
        >
          <strong>Instagram</strong>
          <Row>
            <CampaignType
              title="Hashtag"
              icon={<FaHashtag size={25} className="icon-color" />}
              text="Continuously collect content from Instagram by subscribing to specific hashtags."
              dispatch={() => setCampaignType(CAMPAIGN_TYPE.igHashtag.type)}
              selected={
                campaignType === CAMPAIGN_TYPE.igHashtag.type ? true : false
              }
            />
          </Row>
          <Row>
            <CampaignType
              title="Mentions"
              icon={<MdAlternateEmail size={25} className="icon-color" />}
              text="See all Instagram media objects where an Instagram account has been mentioned."
              dispatch={() => setCampaignType(CAMPAIGN_TYPE.igMentions.type)}
              selected={
                campaignType === CAMPAIGN_TYPE.igMentions.type ? true : false
              }
            />
          </Row>
        </Container>
        <Row>
          <CampaignType
            title="Web form"
            icon={
              <FiUpload
                size={25}
                style={{ strokeWidth: "3" }}
                className="icon-color"
              />
            }
            text="Set up a web form where end-users can upload their content, including bigger files."
            dispatch={() => setCampaignType(CAMPAIGN_TYPE.upload.type)}
            selected={campaignType === CAMPAIGN_TYPE.upload.type ? true : false}
          />
        </Row>
        <Row>
          <CampaignType
            title="WhatsApp"
            icon={<FaWhatsapp size={25} className="icon-color" />}
            text="Invite end-users to send content into Reeler via WhatsApp."
            dispatch={() => setCampaignType(CAMPAIGN_TYPE.whatsapp.type)}
            selected={
              campaignType === CAMPAIGN_TYPE.whatsapp.type ? true : false
            }
            disabled={campaigns?.some(
              c => c.campaignType === CAMPAIGN_TYPE.whatsapp.type
            )}
          />
        </Row>
        <Row>
          <CampaignType
            title="Email"
            icon={<FaRegEnvelope size={25} className="icon-color" />}
            text="Invite end-users to send content into Reeler via email."
            dispatch={() => setCampaignType(CAMPAIGN_TYPE.email.type)}
            selected={campaignType === CAMPAIGN_TYPE.email.type ? true : false}
          />
        </Row>
      </Container>
    )
  }

  const CampaignType = ({
    title,
    icon,
    text,
    dispatch,
    selected,
    disabled = false,
  }) => {
    return (
      <button
        className={
          selected ? "campaign-type campaign-type-selected" : "campaign-type"
        }
        style={{ background: "#fff" }}
        onClick={dispatch}
        disabled={disabled}
      >
        <div
          className="d-flex justify-content-center"
          style={{ width: "50px" }}
        >
          {icon}
        </div>
        <div className="d-flex flex-column w-100">
          <strong className="d-flex">{title}</strong>
          <span className="d-flex">{text}</span>
        </div>
      </button>
    )
  }

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <div onClick={openModal}>{btn}</div>
      </div>
      <Modal size="lg" ref={modalRef} show={open} onHide={closeModal}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add new content source</Modal.Title>
          </Modal.Header>

          <ChooseCampaignType />

          {campaignType && (
            <Modal.Body>
              <Form.Group>
                <Form.Label>2. Content source name (internal)</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <Form.Text muted>
                  Internal use only to keep track of your content sources.
                </Form.Text>
              </Form.Group>
            </Modal.Body>
          )}

          <Modal.Footer>
            <ReelerButton
              text="Close"
              styleClass="btn-secondary"
              dispatch={closeModal}
            />
            <ReelerButton
              text="Create content source"
              styleClass="btn-main"
              disabled={campaignType && name ? false : true}
              type="submit"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default CreateCampaign
