import React from "react"
import { Button } from "react-bootstrap"
import SpinnerComponent from "./SpinnerComponent"

export default function ReelerButton({
  loading,
  disabled,
  dispatch,
  type = "button",
  text = "",
  styleClass = "btn-main",
  spinnerClass,
  spinnerColor,
  size = "sm",
  msg = null,
}) {
  return (
    <Button
      type={type}
      disabled={loading || disabled}
      onClick={dispatch}
      className={styleClass}
      size={size}
    >
      <div className="d-flex align-items-center flex-row justify-content-center">
        {msg ? (
          <span>{msg.text}</span>
        ) : (
          <>
            <span>{text}</span>

            {loading ? (
              <SpinnerComponent
                size="sm"
                spinnerClass={spinnerClass}
                style={spinnerColor}
              />
            ) : null}
          </>
        )}
      </div>
    </Button>
  )
}
