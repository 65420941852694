import React, { useEffect, useState } from "react"
import { BulkEditMessageReset } from "../../../redux/bulk-edit/bulkEdit.actions"
import { selectBulkEditMsg } from "../../../redux/bulk-edit/bulkEdit.selectors"
import { useDispatch, useSelector } from "react-redux"
import parse from "html-react-parser"

import * as S from "./styles"
import { Link } from "react-router-dom"
import CloseButton from "../CloseButton"

const BulkEditAlert = () => {
  const msg = useSelector(selectBulkEditMsg)
  const dispatch = useDispatch()

  useEffect(() => {
    if (msg && msg?.duration) {
      setTimeout(() => {
        dispatch(BulkEditMessageReset())
      }, msg.duration)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msg])

  const hideAlert = () => {
    dispatch(BulkEditMessageReset())
  }

  return msg ? (
    <S.AlertContainer>
      <S.Alert
        bgColor={
          msg.type === "success"
            ? "var(--reeler-light)"
            : msg.type === "danger"
            ? "var(--reeler-danger-light)"
            : msg.type === "info"
            ? "var(--reeler-container)"
            : "var(--reeler-container)"
        }
        borderColor={
          msg.type === "success"
            ? "var(--reeler)"
            : msg.type === "danger"
            ? "var(--reeler-danger)"
            : msg.type === "info"
            ? "var(--reeler-grey)"
            : "var(--reeler-grey)"
        }
      >
        <S.AlertBodyContainer>
          <S.CloseButtonContainer onClick={() => hideAlert()}>
            <CloseButton aria-label="Hide" />
          </S.CloseButtonContainer>
          <div>
            <S.AlertLead>{msg.lead}</S.AlertLead>
            <S.AlertText> {parse(msg.text)}</S.AlertText>
          </div>
        </S.AlertBodyContainer>
      </S.Alert>
    </S.AlertContainer>
  ) : null
}

export default BulkEditAlert
