import React, { useState } from "react"

import { Dropdown } from "react-bootstrap"

// 3 parties
import {
  FaEllipsisV,
  FaChevronUp,
  FaChevronDown,
  FaGripVertical,
} from "react-icons/fa"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  changeFieldPosition,
  selectField,
  setShowEditModal,
} from "../../../../redux/campaign/campaign.actions"
import { selectFormSchema } from "../../../../redux/campaign/campaign.selectors"

// Reeler
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
import * as S from "../styles"

import CopyWidget from "./CopyWidget"
import RemoveWidgetFromForm from "./RemoveWidgetFromForm"
import AddNewFieldDropdown from "./AddNewFieldDropdown"
import { Draggable } from "react-beautiful-dnd"

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <S.FormFieldControl
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <ReelerTooltip text="More options">
      <FaEllipsisV />
    </ReelerTooltip>
  </S.FormFieldControl>
))

export default function FormField(props) {
  const { fieldId, field, children, disableHover, index } = props
  const [active, setActive] = useState(false)
  const dispatch = useDispatch()
  const formSchema = useSelector(selectFormSchema)

  const editField = () => {
    dispatch(selectField(fieldId))
    dispatch(setShowEditModal(true))
  }

  return (
    <Draggable key={fieldId} draggableId={fieldId} index={index}>
      {provided => (
        <S.FormFieldContainer
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className="draggable-container"
          onMouseEnter={() => setActive(true)}
          onMouseLeave={() => setActive(false)}
          $disableHover={active && disableHover}
        >
          <S.FormFieldDragNDrop>
            {active ? (
              <ReelerTooltip text="Drag and drop">
                <FaGripVertical className="icon-btn" />
              </ReelerTooltip>
            ) : null}
          </S.FormFieldDragNDrop>

          <S.ChildrenContainer>{children}</S.ChildrenContainer>

          <S.FormFieldControlsContainer $active={active && !disableHover}>
            {/* <S.FormFieldControl>
              <AddNewFieldDropdown previousFieldId={fieldId} />
            </S.FormFieldControl> */}
            {/* Hide copy for upload, email & submit fields */}
            {!["submitButton", "file", "email"].includes(fieldId) ? (
              <S.FormFieldControl>
                <CopyWidget previousFieldId={fieldId} field={field} />
              </S.FormFieldControl>
            ) : null}
            {/* <S.FormFieldControl>
              <ReelerTooltip text="Move up">
                <FaChevronUp onClick={() => changePosition(-1)} />
              </ReelerTooltip>
            </S.FormFieldControl>
            <S.FormFieldControl>
              <ReelerTooltip text="Move down">
                <FaChevronDown onClick={() => changePosition(1)} />
              </ReelerTooltip>
            </S.FormFieldControl> */}
            <S.FormFieldControl>
              <ReelerTooltip text="Edit field">
                <small
                  style={{ color: "var(--reeler-icon)" }}
                  onClick={() => editField()}
                >
                  Edit
                </small>
              </ReelerTooltip>
            </S.FormFieldControl>

            {/* Hide delete for upload & submit buttons */}
            {!["submitButton", "email"].includes(fieldId) ? (
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="more-options"
                ></Dropdown.Toggle>
                <Dropdown.Menu className="super-colors">
                  <Dropdown.Item eventKey="2">
                    <RemoveWidgetFromForm fieldId={fieldId} />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
          </S.FormFieldControlsContainer>
        </S.FormFieldContainer>
      )}
    </Draggable>
  )
}
